<template>
  <div>
    <div class="no-selection-itinerary" id="header" style="padding-left: 3.5%">
      <td nowrap>
        <img
          class="logo-header"
          :src="require('@/assets/img/sidebar/hovers/icon-itinerary.svg')"
        />
        <h1
          style="padding-bottom: 4px"
          class="css-titulo-header margin-title-header"
        >
          {{ langFilter("projects") }}
        </h1>
      </td>
      <div style="margin-bottom: 70px"></div>
    </div>
    <div class="myProjects-MainContainer">
      <div class="newProjectV2-TabSelectorContainer">
        <div style="display: inline-block; margin: auto; text-align: center">
          <label
            :class="(steps > 0 ? 'success' : '') + (steps == 0 ? 'step' : '')"
            @click="stepsFunction(0)"
            >{{ langFilter("template") }}</label
          >
          <label
            :class="(steps > 1 ? 'success' : '') + (steps == 1 ? 'step' : '')"
            @click="stepsFunction(1)"
            >{{ langFilter("project-info") }}</label
          >

          <label
            :class="(steps > 2 ? 'success' : '') + (steps == 2 ? 'step' : '')"
            @click="stepsFunction(2)"
            >{{ langFilter("assistants") }}</label
          >
          <label
            :class="(steps > 3 ? 'success' : '') + (steps == 3 ? 'step' : '')"
            @click="stepsFunction(3)"
            >{{ langFilter("content") }}</label
          >

          <label
            :class="(steps > 4 ? 'success' : '') + (steps == 4 ? 'step' : '')"
            @click="stepsFunction(4)"
            >{{ langFilter("users") }}</label
          >
          <label
            :class="(steps > 5 ? 'success' : '') + (steps == 5 ? 'step' : '')"
            style="margin-right: 0px"
            >{{ langFilter("start") }}</label
          >
        </div>
      </div>

      <div v-if="steps == 1">
        <div class="newProject-ProjectDataContainer">
          <label class="newProject-ProjectDataCategoryTitle">
            2. &nbsp;&nbsp;&nbsp;{{ langFilter("projectName") }}
          </label>
          <textarea
            class="newProject-projectName-textarea"
            id="newProjectV2-ProjectName"
            cols="1"
            rows="1"
            v-model="projectName"
            :placeholder="langFilter('insertProjectName') + '...'"
            @keypress.enter.prevent
          />
          <label class="newProject-ProjectDataCategoryTitle">
            3. &nbsp;&nbsp;&nbsp;{{ langFilter("selectProgram") }}
          </label>
          <div
            class="new-projectV2-select-search-container"
            @click.stop="activeSelect"
            v-outside="disableSelect"
          >
            <input
              class="newProject-Input placeholder-black"
              id="newProject-ProgramInput"
              placeholder="Selecciona el programa asociado..."
              style="margin-bottom: 79px"
              v-model="selected_program"
              readonly
            />

            <div class="new-project-select-search" v-if="selectMostrar">
              <input
                type="text"
                :placeholder="langFilter('Search...')"
                v-model="searchPrograms"
                class="newProject-Input"
              />
              <ul>
                <li
                  v-for="(progr, indexProgram) in search_programs"
                  :key="indexProgram"
                  @click="selectedValueProgram(progr.name, progr.program_id)"
                >
                  {{ progr.name }}
                </li>
              </ul>
            </div>
          </div>

          <label class="newProject-ProjectDataCategoryTitle">
            4. &nbsp;&nbsp;&nbsp;{{ langFilter("projectDuration") }}
          </label>
          <div class="row align-center">
            <div class="col-6 align-left">
              <p class="label-new-projV2">
                {{ langFilter("inicioProyecto") }}
              </p>
              <input
                v-model="start_date"
                id="start_date"
                class="input-new-projV2 date-prog"
                type="date"
              />
            </div>
            <div class="col-6 align-left">
              <p class="label-new-projV2">
                {{ langFilter("finalProyecto") }}
              </p>
              <input
                v-model="end_date"
                id="end_date"
                class="input-new-projV2 date-prog"
                type="date"
              />
            </div>
          </div>
          <label class="newProject-ProjectDataCategoryTitle">
            5. &nbsp;&nbsp;&nbsp;{{ langFilter("URL Gestor de tareas") }}
          </label>
          <textarea
            :class="textareaClass"
            id="newProjectV2-taskManager"
            cols="1"
            rows="1"
            v-model="taskManager"
            :placeholder="langFilter('URL Gestor de tareas') + '...'"
            @keypress.enter.prevent
          />
          <p v-if="taskManagerError" class="error-message">
            {{ langFilter("validUrl") }}
          </p>
        </div>
        <div
          class="newProjectV2-NextButton"
          :class="{
            'nextButton-clickable':
              projectName != '' &&
              selected_program != '' &&
              start_date != '' &&
              end_date != '',
          }"
          @click="stepsFunction(steps + 1)"
        >
          <p class="newProject-ButtonText">
            {{ langFilter("next") }}
          </p>
        </div>
      </div>

      <alertMessage :lang="lang" ref="alerts"></alertMessage>
    </div>

    <div v-if="steps == 0">
      <div class="newProject-ProjectDataContainer">
        <label
          v-if="loadingTemplates"
          class="newProject-ProjectDataCategoryTitle"
        >
          1. &nbsp;&nbsp;&nbsp;{{ langFilter("selectTemplateTitle") }}
        </label>
      </div>

      <div
        v-if="templatesShown && !loadingTemplates"
        class="newProject-ProjectBack pointer"
      >
        <img
          @click="loadingTemplates = true"
          class="pointer"
          :src="require('@/assets/img/general/arrow-right-purple.svg')"
          alt="back"
        />
        <label class="newProject-ProjectDataCategoryTitle">
          &nbsp;&nbsp;&nbsp;{{ langFilter("arrowBack") }}
        </label>
      </div>

      <!-- Nuevas cards de opciones para plantillas -->
      <div
        v-if="loadingTemplates"
        class="newProject-templates-categoryContainer border-0"
      >
        <div class="templates-templatesList">
          <div class="templates-templateCard-selection">
            <div style="background-color: #f3f4ff">
              <img
                class="templates-templateCardImage"
                :src="require('@/assets/img/projects/chooseTemplate.svg')"
              />
            </div>
            <div class="templates-templateCardText d-flex flex-column h-100">
              <p
                class="templates-templateCardTitle flex-grow-1"
                :class="{ draft: page == 1 }"
              >
                {{ langFilter("firstOptionTitle") }}
              </p>
              <span class="templates-templateCardContent flex-grow-1">
                {{ langFilter("firstOptionText") }}</span
              >
              <div
                class="d-flex justify-content-center align-items-center flex-grow-1"
              >
                <button
                  @click="setNext"
                  class="btn btn-sm rounded-pill"
                  style="background-color: #815bf0; color: #ffffff"
                >
                  {{ langFilter("next") }}
                </button>
              </div>
            </div>
          </div>

          <div class="templates-templateCard-selection">
            <div style="background-color: #f3f4ff">
              <img
                class="templates-templateCardImage"
                :src="require('@/assets/img/projects/importTemplate.svg')"
              />
            </div>
            <div class="templates-templateCardText d-flex flex-column h-100">
              <p
                class="templates-templateCardTitle flex-grow-1"
                :class="{ draft: page == 1 }"
              >
                {{ langFilter("secondOptionTitle") }}
              </p>
              <span class="templates-templateCardContent flex-grow-1">
                {{ langFilter("secondOptionText") }}</span
              >

              <div
                class="d-flex justify-content-center align-items-center flex-grow-1"
              >
                <button
                  @click="toggleImportTemplateModal"
                  class="btn btn-sm rounded-pill"
                  style="background-color: #815bf0; color: #ffffff"
                >
                  {{ langFilter("next") }}
                </button>
              </div>

              <!-- Import Template Modal -->
              <import-template-modal
                v-if="showImportTemplate"
                @toggle-modal="toggleImportTemplateModal"
                @file-selected="handleFileSelected"
                :selected_program_id="selected_program_id"
                :space_id="space.id"
                :projectName="projectName"
                :lang="lang"
              />
            </div>
          </div>

          <div class="templates-templateCard-selection">
            <div style="background-color: #f3f4ff">
              <img
                class="templates-templateCardImage"
                :src="require('@/assets/img/projects/createTemplate.svg')"
              />
            </div>
            <div class="templates-templateCardText d-flex flex-column h-100">
              <p
                class="templates-templateCardTitle flex-grow-1 mb-3"
                :class="{ draft: page == 1 }"
              >
                {{ langFilter("thirdOptionTitle") }}
              </p>
              <span class="templates-templateCardContent flex-grow-1 mb-3">
                {{ langFilter("thirdOptionText") }}
              </span>
              <div
                class="d-flex justify-content-center align-items-center flex-grow-1"
              >
                <button
                  @click="redirectNewTemplate()"
                  class="btn btn-sm rounded-pill"
                  style="background-color: #815bf0; color: #ffffff"
                >
                  {{ langFilter("next") }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div v-if="templatesShown && !loadingTemplates">
        <div
          class="newProject-templates-categoryContainer"
          v-for="(category, categoriesIndex) in categoriesContainer"
          :key="categoriesIndex"
          :id="'categoryContainer-' + categoriesIndex"
        >
          <div class="templates-categoryContainer-title">
            {{ category.name }}
          </div>
          <div class="templates-templatesList">
            <div
              v-for="(template, templatesIndex) in listTemplates"
              :key="templatesIndex"
              :id="'template-card-' + templatesIndex"
            >
              <div
                id="trackSelector"
                v-on:click="onTemplateClick($event, template)"
              >
                <div
                  class="templates-templateCard"
                  v-if="
                    template.category_id == category.id && template.draft == 0
                  "
                >
                  <div
                    class="templates-templateCardImage"
                    :style="`background-image: url('${getTrackHeader(
                      template.img
                    )}'); `"
                  ></div>
                  <div class="templates-templateCardText">
                    <p
                      class="templates-templateCardTitle"
                      :class="{ draft: page == 1 }"
                    >
                      {{ template.name }}
                    </p>
                    <p>
                      <span
                        class="templates-templateCardCreatedBy"
                        v-if="
                          template.created_by != '' &&
                          template.created_by != undefined &&
                          template.created_by != null
                        "
                        >{{ langFilter("Created by") }}:
                        <span
                          class="templateCardPurple"
                          :class="{ draft: page == 1 }"
                          >{{ template.creatorName }}</span
                        >
                      </span>
                      <span
                        class="templates-templateCardCategory"
                        :class="{ draft: page == 1 }"
                        >{{ langFilter("Category") }}:
                        <span
                          class="templateCardPurple"
                          :class="{ draft: page == 1 }"
                          >{{ template.category_name }}</span
                        >
                      </span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <templateModal
        :lang="lang"
        :track="trackSelected"
        :categories="listCategories"
        :_token="_token"
        :selectableProject="true"
        ref="templateModal"
      ></templateModal>

      <div v-if="!templatesShown && !loadingTemplates">
        <img
          :src="require('@/assets/img/general/loading.gif')"
          class="loading-rocket-templates"
        />
      </div>
    </div>

    <div v-if="steps == 2" class="container">
      <NewProjectsAssistant
        :templateExpert="assistant_id"
        :templateEvaluator="evaluator_id"
        :assistantTemplate="assistantTemplate"
        :lang="lang"
        @update-expert="updateAssistantId"
        @update-evaluator="updateEvaluatorId"
      />
      <div
        class="newProjectV2-NextButton"
        :class="{
          'nextButton-clickable': assistant_id,
        }"
        @click="stepsFunction(steps + 1)"
      >
        <p class="newProject-ButtonText">
          {{ langFilter("next") }}
        </p>
      </div>
    </div>

    <div v-if="steps >= 3">
      <div v-show="steps == 3">
        <newprojectsContent
          ref="content"
          :template="selected_template"
          :projectName="projectName"
          :chaptersSaved="chaptersSaved"
          :templateIdSaved="templateIdSaved"
          :syllabus="syllabus"
          :space="space.id"
          :taskManagerUrl="taskManager"
          @stepsFunction="stepsFunction"
          @openEditModal="openEditModal"
          @updateInfo="updateInfo($event)"
          @updateProjectName="updateProjectName"
          @updateChaptersInfo="updateChaptersInfo"
          @showConfirmationModalInfo="showConfirmationModalInfo($event)"
          @taskManager="taskManager = $event"
          :lang="lang"
        />
      </div>
    </div>
  </div>
  <div class="modalV2-question-container" v-if="modalTask">
    <div class="modalV2-question-box">
      <span>
        <img
          :src="require('@/assets/img/projects/close_modal.svg')"
          class="newProjectV2-close-modal"
          @click="modalTask = false"
        />
      </span>
      <h1 class="modalV2-question-title">{{ langFilter("edit_question") }}</h1>
      <div class="modalV2-question-scroll">
        <div class="modalV2-question-input-container">
          <p class="modalV2-question-input-title">
            {{ langFilter("question") }}*
          </p>
          <textarea
            class="modalV2-question-textarea"
            :placeholder="langFilter('writeQuestion')"
            v-model="addTask.task"
            id="newProject-QuestionTitle"
          ></textarea>
        </div>
        <div class="modalV2-question-input-container">
          <p class="modalV2-question-input-title">Prompt</p>
          <textarea
            class="modalV2-question-textarea"
            :placeholder="langFilter('writePrompt')"
            v-model="addTask.prompt"
            id="newProject-PromptTitle"
          ></textarea>
        </div>
        <div class="modalV2-question-input-container">
          <p class="modalV2-question-input-title">
            {{ langFilter("typology") }}
          </p>
          <select
            type="text"
            class="modalV2-question-input"
            v-model="addTask.type"
            id="newProject-QuestionType"
          >
            <option value="1" selected>
              {{ langFilter("taskType1") }}
            </option>
            <option value="2" selected>
              {{ langFilter("taskType2") }}
            </option>
            <option value="3" selected>
              {{ langFilter("taskType3") }}
            </option>
            <option value="4" selected>
              {{ langFilter("taskType4") }}
            </option>
            <option value="5" selected>
              {{ langFilter("taskType5") }}
            </option>
          </select>

          <div v-if="addTask.type == 2">
            <div
              v-for="(check, indexCheck) in addTask.newQuestionChecks"
              :key="indexCheck"
              style="margin-top: 5px"
            >
              <label class="checkbox-inline">
                <input
                  type="radio"
                  name="newQuestionCheck"
                  v-bind:value="indexCheck"
                  v-model="addTask.radio"
                  class="newProject-RadioBtnTask"
                />
              </label>
              <input
                type="text"
                v-model="check.name"
                :placeholder="langFilter('enter response')"
                class="newProject-InputCheckTask"
                id="newProject-QuestionChecks"
              />
              <img
                :src="require('@/assets/img/projects/close_modal.svg')"
                class="newProjectV2-delete-cross"
                @click="addTask.newQuestionChecks.splice(indexCheck, 1)"
              />
            </div>
            <label class="newProject-AddCheckbox" @click="addTaskCheck">{{
              langFilter("+ Add")
            }}</label>
          </div>

          <div v-if="addTask.type == 3">
            <div
              v-for="(check, indexCheck) in addTask.newQuestionChecks"
              :key="indexCheck"
              style="margin-top: 5px"
            >
              <label class="checkbox-inline">
                <input
                  type="checkbox"
                  name="newQuestionCheck"
                  v-bind:value="indexCheck"
                  v-model="check.correct"
                  class="newProject-RadioBtnTask"
                />
              </label>
              <input
                type="text"
                v-model="check.name"
                :placeholder="langFilter('enter response')"
                class="newProject-InputCheckTask"
                id="newProject-QuestionChecks"
              />
              <img
                :src="require('@/assets/img/projects/close_modal.svg')"
                class="newProjectV2-delete-cross"
                @click="addTask.newQuestionChecks.splice(indexCheck, 1)"
              />
            </div>
            <label class="newProject-AddCheckbox" @click="addTaskCheck">{{
              langFilter("+ Add")
            }}</label>
          </div>
        </div>
        <div class="modalV2-question-input-container">
          <p class="modalV2-question-input-title">
            {{ langFilter("description") }}
          </p>
          <textarea
            class="modalV2-question-textarea"
            :placeholder="langFilter('description') + '...'"
            v-model="addTask.instructions"
          ></textarea>
        </div>

        <div
          v-if="!taskEdit"
          class="modalV2-question-saveBtn"
          @click="MoreTasks()"
        >
          <p>Guardar</p>
        </div>
        <div
          v-if="taskEdit"
          class="modalV2-question-saveBtn"
          @click="saveEditTask()"
        >
          <p>Guardar</p>
        </div>
      </div>
    </div>
  </div>

  <div v-show="steps == 4">
    <itinerary-users
      @usersInvite="inviteUsers($event)"
      :lang="lang"
      :editing="true"
      :space="space"
      :project_id="null"
    />
    <div
      class="newProjectV2-NextButton"
      :class="{
        'nextButton-clickable':
          projectName != '' &&
          selected_program != '' &&
          start_date != '' &&
          end_date != '' &&
          checkUsers(),
      }"
      @click="checkUsers() ? stepsFunction(steps + 1) : null"
    >
      <p class="newProject-ButtonText">
        {{ langFilter("next") }}
      </p>
    </div>
  </div>

  <div v-if="steps == 5">
    <div class="newProjectV2-UsersContainer">
      <img
        :src="require('@/assets/img/projects/Tierra_Luna.png')"
        class="newProject-StartImage"
      />
      <h2 class="newProject-StartTitle">
        {{ langFilter("departing!") }}
      </h2>
      <p class="newProject-StartText">
        {{ langFilter("email sent") }}
      </p>
    </div>
  </div>

  <confirmation-modal
    v-if="confirmationModal"
    @closeConfirmation="showConfirmationModalInfo"
    @confirm="confirmationConfirm"
    :lang="lang"
    :body="bodyMsg"
    :warning="warning ? langFilter('warningTxt') : null"
    :fixed="true"
    :info="info"
  />
</template>
<script>
import "../../../node_modules/froala-editor/css/froala_editor.pkgd.min.css";
// import FroalaEditor from "../../../node_modules/froala-editor/js/froala_editor.pkgd.min.js";
import translationMixin from "../../mixins/translationMixin.js";
// import { VueDraggableNext } from "vue-draggable-next";
import { Modal } from "bootstrap";
import axios from "axios";
import $ from "jquery";
import translations from "./translate/NewProjectsV2Component.json";

import NewProjectsAssistantComponent from "./NewProjectsAssistantComponent.vue";

export default {
  props: ["user_id", "lang", "role", "space"],

  components: {
    // draggable: VueDraggableNext,
    NewProjectsAssistant: NewProjectsAssistantComponent,
  },
  data() {
    return {
      assistantTemplate: false,
      assistant_id: "",
      evaluator_id: "",
      savedTask: [],
      taskEdit: false,
      modalTask: false,
      showImportTemplate: false,
      addTask: {
        task: "",
        type: 1,
        instructions: "",
        prompt: "",
        newQuestionChecks: [],
        radio: 0,
        optional: false,
        chapter: 0,
        part: 0,
        edited: true,
      },
      taskManager: "",
      tabs: 1,
      steps: 0,
      projectName: "",
      programs: [],
      assistants: [],
      listTemplates: [],
      listCategories: [],
      trackSelected: "",
      selectorModal: 0,
      trackToUse: "",
      syllabus: "",
      selectMostrar: false,
      selected_program: "",
      selected_program_id: "",
      searchPrograms: "",
      selected_assistant: "",
      selected_assistant_id: "",
      selected_template: "",
      selected_file: null,
      start_date: "",
      end_date: "",
      confirmationModal: false,
      chaptersSaved: [],
      templateIdSaved: "",
      taskManagerError: false,
      errors: [],
      usersInvite: {
        tutors: [],
        members: [],
        invited: [],
      },
      succesful: [],
      loadingTemplates: true,
      templatesShown: false,
      body: "noSave",
      info: false,
      warning: false,
      traducciones: translations,
    };
  },
  created() {
    axios
      .post(`${process.env.VUE_APP_API_URL}/getProgramsBySpace`, {
        customer: this.space.id,
      })
      .then((response) => {
        this.programs = response.data;
      });
  },
  mounted() {},
  methods: {
    toggleImportTemplateModal() {
      this.showImportTemplate = !this.showImportTemplate;
    },

    updateAssistantId(newExpertId) {
      this.assistant_id = newExpertId;
    },
    updateEvaluatorId(newEvaluatorId) {
      this.evaluator_id = newEvaluatorId;
    },

    redirectNewTemplate() {
      this.selected_file = null;
      this.assistant_id = "";
      this.evaluator_id = "";
      this.assistantTemplate = false;
      this.selected_template = { id: -1 };
      this.chaptersSaved = [];
      this.stepsFunction(1);
    },

    handleFileSelected(file) {
      this.selected_file = file;
      this.toggleImportTemplateModal();
      this.selected_template = { id: -1 };
      this.chaptersSaved = [];
      this.stepsFunction(1);
    },

    setNext() {
      this.loadingTemplates = false;
      this.templatesShown = false;
      axios
        .post(`${process.env.VUE_APP_API_URL}/tracks/getAllTemplatesCategory`, {
          lang: this.lang,
        })
        .then((response) => {
          this.listCategories = response.data;
        });
      axios
        .post(`${process.env.VUE_APP_API_URL}/tracks/getAllTracks`, {
          space_id: this.space.id,
          lang: this.lang,
        })
        .then((response) => {
          this.listTemplates = response.data;
          this.mergeCategories();
          this.templatesShown = true;
        });
    },

    showConfirmationModalInfo(data) {
      this.info = true;
      this.bodyMsg = data;
      this.warning = false;
      this.confirmationModal = !this.confirmationModal;
    },
    checkUsers() {
      return (
        this.usersInvite.tutors.length > 0 ||
        this.usersInvite.members.length > 0
      );
    },
    openEditModal(chapter, part, task) {
      this.taskEdit = true;
      this.modalTask = true;
      this.savedTask = [chapter, part, task];

      this.addTask = JSON.parse(
        JSON.stringify(
          this.$refs.content.chapters[chapter].parts[part].tasks[task]
        )
      );
    },
    sendQuestionToValidate() {
      var valid = true;
      var characters = false;

      if (this.addTask.task == "") {
        $("#newProject-QuestionTitle").addClass("newProject-InputValidation");
        valid = false;
      } else {
        $("#newProject-QuestionTitle").removeClass(
          "newProject-InputValidation"
        );
      }
      if (this.addTask.question == "") {
        $("#newProject-QuestionQuestion").addClass(
          "newProject-InputValidation"
        );
        valid = false;
      } else {
        $("#newProject-QuestionQuestion").removeClass(
          "newProject-InputValidation"
        );
      }

      if (this.addTask.type == 3) {
        var paso = false;
        this.addTask.newQuestionChecks.forEach(function (element) {
          if (element.correct == true) {
            paso = true;
          }
          if (element.name.length == 0) {
            characters = true;
          }
        });
        if (this.addTask.newQuestionChecks.length < 2) {
          paso = false;
        }
        if (paso == false || characters == true) {
          $("#newProject-QuestionChecks").addClass(
            "newProject-InputValidation"
          );
          $("#newProject-QuestionType").addClass("newProject-InputValidation");
          valid = false;
        } else {
          $("#newProject-QuestionChecks").removeClass(
            "newProject-InputValidation"
          );
          $("#newProject-QuestionType").removeClass(
            "newProject-InputValidation"
          );
        }
      }

      if (this.addTask.type == 2) {
        if (this.addTask.newQuestionChecks.length < 2) {
          $("#newProject-QuestionChecks").addClass(
            "newProject-InputValidation"
          );
          $("#newProject-QuestionType").addClass("newProject-InputValidation");
          valid = false;
        } else {
          this.addTask.newQuestionChecks.forEach(function (element) {
            if (element.name.length == 0) {
              characters = true;
            }
          });
          if (characters == true) {
            $("#newProject-QuestionChecks").addClass(
              "newProject-InputValidation"
            );
            $("#newProject-QuestionType").addClass(
              "newProject-InputValidation"
            );
          } else {
            $("#newProject-QuestionChecks").removeClass(
              "newProject-InputValidation"
            );
            $("#newProject-QuestionType").removeClass(
              "newProject-InputValidation"
            );
          }
        }
      }
      if (valid == true && characters == false) {
        return true;
      } else {
        this.$refs.alerts.title = "¡Incompleted fields!";
        this.$refs.alerts.text = "To continue you must fill all the fields.";
        this.openErrorsAlert();
        return false;
      }
    },
    saveEditTask() {
      if (this.sendQuestionToValidate()) {
        this.modalTask = false;
        this.addTask.edited = true;
        this.$refs.content.chapters[this.savedTask[0]].parts[
          this.savedTask[1]
        ].tasks[this.savedTask[2]] = this.addTask;
        this.addTask = {
          task: "",
          type: 1,
          // question: "",
          instructions: "",
          prompt: "",
          // help: "",
          newQuestionChecks: [],
          radio: 0,
          chapter: 0,
          part: 0,
          edited: true,
        };
      }
    },
    addTaskCheck() {
      this.addTask.newQuestionChecks.push({ name: "", correct: false });
    },
    MoreTasks() {
      if (this.sendQuestionToValidate()) {
        this.$refs.content.chapters[this.addTask.chapter].parts[
          this.addTask.part
        ].tasks.push(this.addTask);
        this.modalTask = false;
        this.addTask = {
          task: "",
          type: 1,
          instructions: "",
          prompt: "",
          newQuestionChecks: [],
          radio: 0,
          chapter: 0,
          part: 0,
          edited: true,
        };
      }
    },
    disableSelect(el) {
      if (
        el.target.className == "new-project-select-search-container"
          ? (this.selectMostrar = true)
          : (this.selectMostrar = false)
      );
    },
    activeSelect(el) {
      if (
        el.target.tagName == "LI"
          ? (this.selectMostrar = false)
          : (this.selectMostrar = true)
      );
    },
    selectedValueProgram(name, id) {
      this.program = id;
      this.selected_program = name;
      this.selectMostrar = false;
    },
    stepsFunction(step) {
      this.stepToJump = step;
      this.templatesShown = false;
      this.loadingTemplates = true;
      switch (step) {
        case 0:
          this.steps = 0;
          break;
        case 1:
          if (this.selected_template != "") {
            this.trackSelected = "";
            this.steps = 1;
            this.templatesShown = true;
          }
          break;
        case 2:
          if (this.validationStep1() && this.selected_template != "") {
            this.steps = 2;
          }
          break;
        case 3:
          if (
            this.validationStep1() &&
            this.selected_template != "" &&
            this.assistant_id
          ) {
            if (this.selected_file) {
              this.steps = 5;
              this.submitNewProject();
            } else {
              this.trackSelected = "";
              this.steps = 3;
              this.templatesShown = true;
            }
          }
          break;
        case 4:
          if (this.validationStep1() && this.validationStep2()) this.steps = 4;
          break;
        case 5:
          this.steps = 5;
          this.submitNewProject();
          break;
      }
    },

    isValidUrl(string) {
      try {
        const url = new URL(string);
        const hostnameValidation = /^([a-z0-9]+(-[a-z0-9]+)*\.)+[a-z]{2,}$/i;
        if (hostnameValidation.test(url.hostname)) {
          return true;
        }
        return false;
      } catch (_) {
        return false;
      }
    },
    validationStep1() {
      var canContinue = true;
      var dates = true;
      if (this.projectName == "") {
        canContinue = false;
        $("#newProjectV2-ProjectName").addClass("newProject-InputValidation");
      } else {
        $("#newProjectV2-ProjectName").removeClass(
          "newProject-InputValidation"
        );
      }

      if (this.selected_program == "") {
        canContinue = false;
        $("#newProject-ProgramInput").addClass("newProject-InputValidation");
      } else {
        $("#newProject-ProgramInput").removeClass("newProject-InputValidation");
      }

      if (this.start_date == "") {
        canContinue = false;
        $("#start_date").addClass("newProject-InputValidation");
      } else {
        $("#start_date").removeClass("newProject-InputValidation");
      }

      if (this.end_date == "") {
        canContinue = false;
        $("#end_date").addClass("newProject-InputValidation");
      } else {
        $("#end_date").removeClass("newProject-InputValidation");
      }

      if (this.end_date != "" && this.start_date != "") {
        if (this.end_date < this.start_date) {
          canContinue = false;
          dates = false;
          $("#end_date").addClass("newProject-InputValidation");
          $("#start_date").addClass("newProject-InputValidation");
        } else {
          $("#end_date").removeClass("newProject-InputValidation");
          $("#start_date").removeClass("newProject-InputValidation");
        }
      }

      if (!this.isValidUrl(this.taskManager) && this.taskManager !== "") {
        canContinue = false;
        this.taskManagerError = true;
      } else {
        this.taskManagerError = false;
      }

      if (canContinue) {
        return true;
      } else {
        if (!dates) {
          this.$refs.alerts.title = "Incorrect dates!";
          this.$refs.alerts.text =
            "The end date must be greater than the start date.";
        } else {
          this.$refs.alerts.title = "¡Incompleted fields!";
          this.$refs.alerts.text = "To continue you must fill all the fields.";
        }
        this.openErrorsAlert();
        return false;
      }
    },
    validationStep2() {
      var canContinue = true;
      var i = 0;

      this.$refs.content.chapters.forEach((chapter) => {
        var j = 0;
        var chapterError = false;
        if (chapter.chapter == "") {
          canContinue = false;

          $("#newProject-Chapter" + i + "Name").addClass(
            "newProject-InputValidationWithoutBorder"
          );
          chapterError = true;
        } else {
          $("#newProject-Chapter" + i + "Name").removeClass(
            "newProject-InputValidationWithoutBorder"
          );
        }
        if (chapter.description == "") {
          canContinue = false;

          $("#newProject-Chapter" + i + "Description").addClass(
            "newProject-InputValidation"
          );
          chapterError = true;
        } else {
          $("#newProject-Chapter" + i + "Description").removeClass(
            "newProject-InputValidation"
          );
        }
        // if(chapter.parts.length==0){
        //         canContinue=false;

        //         $("#newProject-Chapter"+i+'Description').addClass('newProject-InputValidation');
        //         chapterError=true;
        // }
        chapter.parts.forEach((part) => {
          // if(part.tasks.length==0){
          //    canContinue=false;

          //     $("#newProject-Tasks"+i+'-'+j).addClass('newProject-AddTasksValidation');
          //     chapterError=true;

          // }else{
          //     $("#newProject-Tasks"+i+'-'+j).removeClass('newProject-AddTasksValidation');

          // }
          if (part.part == "") {
            canContinue = false;

            $("#newProject-Part" + i + "-" + j + "Name").addClass(
              "newProject-InputValidationWithoutBorder"
            );
            chapterError = true;
          } else {
            $("#newProject-Part" + i + "-" + j + "Name").removeClass(
              "newProject-InputValidationWithoutBorder"
            );
          }
          j = j + 1;
        });
        if (chapterError) {
          $("#newProject-ChapterError" + i).addClass(
            "newProject-ContainerValidation"
          );
        } else {
          $("#newProject-ChapterError" + i).removeClass(
            "newProject-ContainerValidation"
          );
        }
        i = i + 1;
      });
      if (!canContinue) {
        this.$refs.alerts.title = "¡Incompleted fields!";
        this.$refs.alerts.text = "To continue you must fill all the fields.";
        this.openErrorsAlert();
      }
      return canContinue;
    },
    openErrorsAlert() {
      if (this.errors.length == 0) {
        this.errors.push("error");

        this.errorTime = {
          animationDuration: "12s",
          animationName: "timebar_progress_x",
        };
        this.setTimeouts = setTimeout(() => {
          this.errors = [];
        }, 12000);
      }
    },
    closeErrorsWindow() {
      clearTimeout(this.setTimeouts);
      this.errors = [];
    },
    mergeCategories() {
      let categoryNames = [];
      this.listTemplates.forEach((template) => {
        this.listTemplates.forEach((template2) => {
          if (
            template.id == template2.id &&
            template.category_id != template2.category_id
          ) {
            categoryNames.push(template2.category_name);
          }
        });
        if (categoryNames.length > 0) {
          categoryNames.push(template.category_name);
          categoryNames = categoryNames.flat();
          categoryNames = categoryNames.filter((v, i, a) => a.indexOf(v) === i);
          template.category_name = categoryNames;
        }
        //empty the array
        categoryNames = [];
      });
      this.listTemplates.forEach((template) => {
        if (Array.isArray(template.category_name)) {
          template.category_name = template.category_name.join(", ");
        }
      });
    },
    getTrackHeader(track) {
      return `${process.env.VUE_APP_API_STORAGE}/img/track_headers/${track}`;
    },
    onTemplateClick(event, value) {
      this.selected_file = null;
      this.trackSelected = value;
      this.selectorModal = 0;
      $("#select-modal-track").val(0);
      if (event.target.id != "text_options") {
        var myModal = Modal.getOrCreateInstance(
          document.getElementById("trackModal")
        );
        myModal.toggle();
      }
    },

    uploadGpt() {
      const formData = new FormData();
      formData.append("doc", this.selected_file);
      formData.append("clean_project", "on");
      formData.append("program_id", this.selected_program_id);
      formData.append("space_id", this.space.id);
      formData.append("project_name", this.projectName);

      if (this.assistant_id) formData.append("assistant_id", this.assistant_id);

      if (this.evaluator_id) formData.append("evaluator_id", this.evaluator_id);

      axios
        .post(`${process.env.VUE_APP_API_URL}/newProject/fromFile`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          this.$router.push(`/itinerary/${response.data.project.id}`);
        })
        .catch((error) => {
          console.error(error);
          alert(
            "Ha habido un error al subir el archivo. Por favor, inténtalo de nuevo más tarde."
          );
        });
    },
    submitNewProject() {
      if (this.selected_file) {
        this.uploadGpt();
        return;
      }

      var formData = new FormData();

      formData.append("program", this.selected_program_id);
      formData.append("start_date", this.start_date);
      formData.append("end_date", this.end_date);
      formData.append("space", this.space.id);

      if (this.assistant_id) formData.append("assistant_id", this.assistant_id);

      if (this.evaluator_id) formData.append("evaluator_id", this.evaluator_id);

      if (this.selected_template.id !== -1)
        formData.append("template", this.selected_template.id);

      if (this.selected_assistant)
        formData.append("templateAssistant", this.selected_assistant_id);

      formData.append("project", this.projectName);
      formData.append("info", this.syllabus);
      formData.append("lang", this.lang);
      formData.append("taskManager", this.taskManager);
      formData.append("tutors", JSON.stringify(this.usersInvite.tutors));
      formData.append("members", JSON.stringify(this.usersInvite.members));
      formData.append("invited", JSON.stringify(this.usersInvite.invited));
      formData.append("chapters", JSON.stringify(this.$refs.content.chapters));

      formData.append(
        "defaultChapters",
        JSON.stringify(this.$refs.content.defaultChapters)
      );

      axios
        .post(
          `${process.env.VUE_APP_API_URL}/myProjects/InsertProjectsV2`,
          formData,
          {
            headers: { "Content-Type": "multipart/form-data" },
          }
        )
        .then((response) => {
          setTimeout(() => {
            window.location.href = "../itinerary/" + response.data.id;
          }, 1000);
        });
    },
    updateInfo(request) {
      this.syllabus = request;
    },
    inviteUsers(users) {
      this.usersInvite = users;
    },
    updateProjectName(name) {
      this.projectName = name;
    },
    updateChaptersInfo(chapters, templateId) {
      this.chaptersSaved = chapters;
      this.templateIdSaved = templateId;
    },
  },
  watch: {
    selected_program() {
      this.selected_program_id = this.programs.find(
        (program) => program.name == this.selected_program
      ).program_id;
    },
    select_assistant() {
      this.selected_assistant_id = this.assistants.find(
        (assistant) => assistant.id == this.select_assistant
      );
    },
  },
  computed: {
    search_programs() {
      return this.programs.filter((program) => {
        return program.name
          .toLowerCase()
          .includes(this.searchPrograms.toLowerCase());
      });
    },
    textareaClass() {
      return [
        "newProject-projectName-textarea",
        this.taskManagerError ? "newProject-InputValidation" : "",
      ];
    },
    categoriesContainer() {
      let categories = this.listCategories;
      let categoriesShown = [];
      categories.forEach((category) => {
        this.listTemplates.forEach((template) => {
          if (category.id === template.category_id && template.draft == 0) {
            categoriesShown.push(category);
          }
        });
      });

      categoriesShown = categoriesShown.filter(
        (category, index, self) =>
          index === self.findIndex((t) => t.id === category.id)
      );

      return categoriesShown;
    },
  },
  mixins: [translationMixin],
};
</script>

<style scoped>
.error-message {
  color: #eabec3;
  bottom: 10px;
  position: relative;
}
</style>

<template>
  <!-- Observations Modal -->
  <div v-if="showObservationsModal" class="modal-general-mask">
    <div class="modal-general-wrapper">
      <div class="modal-send-correction-container div-center">
        <div class="modal-send-correction-body">
          <label class="send-correction-observations-title">
            {{ langFilter("observations") }}
          </label>
          <textarea
            disabled
            :value="observations"
            class="send-correction-observations-textarea"
          ></textarea>
        </div>
        <div class="modal-send-correction-footer">
          <div class="modal-send-correction-footer-buttons">
            <button
              class="modal-send-correction-footer-button"
              @click="closeObservationsModal"
            >
              {{ langFilter("close") }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="relative">
    <alertMessage :lang="lang" ref="alerts" />
    <div
      :class="[{ 'back-arrow-cdti': cdti }, 'back-arrow-solution']"
      @click="clickBackArrow"
    >
      <img :src="require('@/assets/img/general/new_back.svg')" alt="back" />
    </div>
    <smart-printing-modal
      v-if="showOptionsPopup"
      @close="showOptionsPopup = false"
      :project_id="this.project_id"
    />
    <div v-if="notInProject" class="error-margin-top">
      <Error
        title="permiso-title"
        body="permiso-body"
        link="link-redirect"
        image="`<img class='cohete-espacio' id='cohete' src='/img/errors/no_autorizado.svg'>`"
        vector="/img/errors/vector_158.svg"
        vamos="permiso-volver"
        header="true"
        :lang="lang"
      />
    </div>
    <div v-else>
      <resources-modal
        v-if="showResources"
        @closeResources="openModalResources()"
        :lang="lang"
        :themes="themes"
        :edit="false"
        :savedResources="[]"
        ref="modalResources"
        style="top: 120px; z-index: 999"
      />
      <solution-header
        ref="header"
        id="main-container"
        @changeProject="changeProject($event)"
        @changePercentage="changePercentage($event)"
        @selectTab="selectedTab = $event"
        :mapOpened="mapOpened"
        :project_id="project_id"
        :station_id="station_id"
        :solution_id="solution_id"
        :loading="loading"
        :percentage="percentage"
        :lang="lang"
        :space="space"
        :role="role"
        :selectedTab="selectedTab"
      />
      <solution-map
        v-if="showMap"
        ref="map"
        :lateralBarMap="lateralBarMap"
        @goToSolution="goToSolution($event)"
        @openModalResources="openModalResources($event)"
        @sendCorrection="sendCorrection"
        @openFiles="openFiles()"
        @showOptionsPopup="showOptionsPopup = true"
        :key="changedProject"
        :project_id="project_id"
        :solution_id="solution_id"
        :allStations="allStations"
        :allSolutions="allSolutions"
        :role="roleInProject"
        :percentage="percentage"
        :lang="lang"
        :changedProject="changedProject"
        :solution_updated="solution_updated"
      />
      <div class="solutions-loading-gif-div" v-if="loandingTop">
        <img
          class="solutions-loading-gif-container"
          :src="require('@/assets/img/general/loading.gif')"
        />
      </div>
      <div ref="scrollContainer" v-if="showAnswers">
        <div
          :class="[
            { 'solution-answer-map-opened': mapOpened },
            ,
            'answer-princ-div-body, chat-body-project-container',
          ]"
        >
          <div>
            <div
              v-if="isTooltipVisible"
              class="solution-tooltip custom-tooltip"
            >
              {{ tooltipTextTranlated }}
            </div>
            <div
              v-if="!tabHasAssistant && !isMainTab"
              class="missing-assistant-alert"
            >
              {{ dynamicTranslations.stillDoNotHave }}

              <span
                @mouseover="isTooltipVisible = true"
                @mouseleave="isTooltipVisible = false"
                class="assistant-type-text"
              >
                {{ dynamicTranslations.assistantType }}
              </span>

              {{ dynamicTranslations.configured }}.

              <t v-if="displayInstructions">
                {{ dynamicTranslations.configureInstruction }}

                <span class="link-text">
                  {{ dynamicTranslations.clickHere }}.
                </span>
              </t>
            </div>
          </div>

          <chat-bot-project
            v-if="showChat && !isMainTab && tabHasAssistant"
            @reloadGpt="showChat($event)"
            :lang="lang"
            :project_id="project_id"
            :selectedTab="selectedTab"
            :assistants-project="assistants"
          />
        </div>
        <div v-if="isMainTab">
          <solution-container
            @editedSolution="editedSolution($event)"
            @sendCorrection="sendCorrection"
            @errorFileUpload="errorFileUpload"
            @uploadCurrentDataOnScroll="uploadCurrentDataOnScroll($event)"
            @scrollingDown="scrollingDown($event)"
            @scrollingUp="scrollingUp($event)"
            @reloadPercentage="getProgressBarByProject"
            @updateSendToCorrect="checkIfSolutionCompleted()"
            @repositionMap="repositionMap($event, 1)"
            @prepareAnswers="prepareAnswersFunc($event)"
            @reloadChatGpt="reloadChatGpt()"
            :id="`scrollContainer-${answerIndex}`"
            v-for="(answer, answerIndex) in answers"
            :key="answerIndex"
            :answerIndex2="answerIndex"
            :loadedPrev="loadedPrev"
            :loadingMoreSolutions="loadingMoreSolutions"
            :answers="answer"
            :solution_name="loadedAnswerNames[answerIndex]"
            :project_id="project_id"
            :mapOpened="mapOpened"
            :loading="loading"
            :role="roleInProject"
            :lang="lang"
            :currentIndex="currentIndex"
            :space_id="space.id"
            :allStations="allStations"
            :allSolutions="allSolutions"
            :station_id="station_id"
          />
        </div>
      </div>
      <solution-send
        v-if="sendCorrectionModal"
        @closeModal="closeCorrectionModal($event)"
        :lang="lang"
        :solution_id="solution_id"
        :station_id="station_id"
        :project_id="project_id"
        :role="roleInProject"
        :space_id="space.id"
      />
    </div>
  </div>
  <div
    v-if="showFiles"
    @click="showFiles = false"
    class="files-uploaded-modal-fade"
  />
  <div v-if="showFiles" class="files-uploaded-modal">
    <button @click="showFiles = false" class="file-close-btn">x</button>
    <div v-if="filesUploaded.length !== 0">
      <div
        v-for="(fileUploaded, fileUploadedIndex) in filesUploaded"
        :key="fileUploadedIndex"
      >
        <div class="row">
          <div class="col-11">
            <a :href="getFilePath(fileUploaded.file_path)" target="_blank">
              <h3 class="file-name">
                {{ fileUploaded.file_name }}
              </h3>
            </a>
          </div>
          <div class="col-1">
            <h3 class="pointer" @click="deleteFile(fileUploaded.id)">X</h3>
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <p class="no-files">No hay archivos subidos en este proyecto</p>
    </div>
  </div>
</template>

<script>
import translationMixin from "../../mixins/translationMixin.js";
import solutionMap from "./SolutionMapComponent.vue";
import solutionContainer from "./SolutionContainerComponent.vue";
import solutionSend from "./SolutionSendCorrectionComponent.vue";
import alertMessage from "../AlertComponent.vue";
import Error from "../ErrorComponent.vue";
import axios from "axios";
import $ from "jquery";

const prepareLateralBarMap = (itinerary, role) => {
  let transformedData = [];

  itinerary.forEach((station) => {
    let allPartsAnswered = true;
    let anyInteractionInStation = false;

    let transformedStation = {
      idStation: station.id,
      nameStation: station.title,
      parts: [],
      allQuestionsAnswered: true,
      anyInteraction: false,
    };

    station.parts.forEach((part) => {
      let allQuestionsAnsweredInPart = true;
      let anyInteractionInPart = false;

      let transformedPart = {
        idPart: part.id,
        namePart: part.title,
        questions: [],
        allQuestionsAnswered: true,
        anyInteraction: false,
      };

      part.questions.forEach((question) => {
        const answer = Object.values(part.answers).find(
          (ans) => ans.question_id === question.id
        );
        const isAnswered =
          answer && answer.answer && answer.answer.trim() !== "";
        const hasInteraction =
          isAnswered &&
          ((role === 1 &&
            answer.ready_correct_entrepreneur === 1 &&
            answer.send_correction_tutor === 1) ||
            (role !== 1 &&
              answer.ready_correct_tutor === 1 &&
              answer.send_correction_entrepreneur === 1));

        let transformedQuestion = {
          questionId: question.id,
          questionName: question.name,
          answer: answer,
          itsEmpty: !isAnswered,
          hasInteraction: hasInteraction,
        };

        if (!isAnswered) {
          allQuestionsAnsweredInPart = false;
        }

        if (hasInteraction) {
          anyInteractionInPart = true;
          anyInteractionInStation = true;
        }

        transformedPart.questions.push(transformedQuestion);
      });

      transformedPart.allQuestionsAnswered = allQuestionsAnsweredInPart;
      transformedPart.anyInteraction = anyInteractionInPart;

      if (!allQuestionsAnsweredInPart) {
        allPartsAnswered = false;
      }

      transformedStation.parts.push(transformedPart);
    });

    transformedStation.allPartsAnswered = allPartsAnswered;
    transformedStation.anyInteraction = anyInteractionInStation;

    transformedData.push(transformedStation);
  });

  return transformedData;
};

export default {
  props: ["role", "lang", "space", "cdti"],
  components: {
    solutionMap,
    solutionContainer,
    solutionSend,
    Error,
    alertMessage,
  },
  data() {
    return {
      showObservationsModal: false,
      observations: "",
      lateralBarMap: [],
      mapOpened: false,
      answers: [],
      errors: [],
      edited: false,
      loading: true,
      loadingMore: false,
      sendCorrectionModal: false,
      allStations: [],
      allSolutions: [],
      showOptionsPopup: false,
      project_id: "",
      solution_id: "",
      station_id: "",
      loadedAnswerNames: [],
      loadingNewSolutions: false,
      notBusy: true,
      canScrollDown: true,
      canScrollUp: true,
      percentage: 0,
      changedProject: 0,
      solution_updated: false,
      notInProject: false,
      loadingMoreSolutions: 0,
      currentIndex: 0,
      roleInProject: 2,
      loadingMoreSol: false,
      initialLoadState: false,
      currentSolutions: [],
      loandingTop: false,
      scrollTopDown: false,
      showAnswers: true,
      prepareAnswers: [],
      loadedPrev: 0,
      showMap: true,
      succesful: [],
      showCdtiResult: false,
      cdtiData: "",
      loadingCdti: false,
      themes: [],
      showResources: false,
      filesUploaded: [],
      showFiles: false,
      showChat: true,
      selectedTab: 0,
      assistants: [],
      isTooltipVisible: false,
      displayInstructions: false,
      traducciones: [
        {
          name: "close",
          es: "Cerrar",
          en: "Close",
        },
        {
          name: "observations",
          es: "Observaciones",
          en: "Observations",
        },
        {
          name: 1,
          es: "Pendiente",
          en: "Pending",
        },
        {
          name: 2,
          es: "Pendiente de revisión",
          en: "Pending review",
        },
        {
          name: 3,
          es: "Pendiente de modificaciones",
          en: "Pending modification",
        },
        {
          name: 4,
          es: "Completado",
          en: "Completed",
        },
        {
          name: "noAnswers",
          es: "No hay respuestas en este apartado",
          en: "No answers in this part",
        },
        {
          name: "expertAssistant",
          es: "Asistente experto",
          en: "Expert assistant",
        },
        {
          name: "evaluatorAssistant",
          es: "Asistente evaluador",
          en: "Evaluator assistant",
        },
        {
          name: "stillDoNotHave",
          es: "Aún no tienes un",
          en: "You still don't have",
        },
        {
          name: "configured",
          es: "Configurado",
          en: "Configured",
        },
        {
          name: "configureInstruction",
          es: "Si deseas configurar uno",
          en: "If you want to set one",
        },
        {
          name: "clickHere",
          es: "Haz click aquí",
          en: "Click here",
        },
        {
          name: "expertAssistantTooltip",
          es: "El asistente experto es quien ayudará a desarrollar el proyecto",
          en: "The expert assistant is the one who will help develop the project.",
        },
        {
          name: "evaluatorAssistantTooltip",
          es: "El asistente evaluador es quién ayudará a evaluar el proyecto y proponer mejoras.",
          en: "The evaluator assistant is the one who will help evaluate the project and suggest improvements.",
        },
      ],
    };
  },
  created() {
    this.initialCreated();
    $("html").css("min-height", "101%");
    window.onpopstate = () => {
      this.clickBack();
    };
  },
  mounted() {
    this.$parent.loading = false;

    if (!this.cdti) {
      setTimeout(() => {
        window.addEventListener("scroll", this.loadMoreFunction);
        // this.repositionMap(this.solution_id, 1);
      }, 2000);
    }
  },
  methods: {
    closeObservationsModal() {
      axios
        .post(`${process.env.VUE_APP_API_URL}/myProjects/deleteObservation`, {
          roleInProject: this.roleInProject,
          project_id: this.project_id,
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.showObservationsModal = false;
        });
    },
    deleteFile(file_id) {
      const file = this.filesUploaded.find((file) => file.id === file_id);
      this.filesUploaded = this.filesUploaded.filter(
        (file) => file.id !== file_id
      );
      axios
        .post(`${process.env.VUE_APP_API_URL}/deleteAssistantFile`, {
          id: file_id,
        })
        .catch((err) => {
          this.filesUploaded.push(file);
          console.error(err);
          alert("Error eliminando el archivo. Por favor, inténtalo de nuevo.");
        });
    },
    reloadChatGpt() {
      this.showChat = false;
      this.$nextTick(() => {
        this.showChat = true;
      });
    },
    openFiles() {
      axios
        .get(
          `${process.env.VUE_APP_API_URL}/getUploadedGptFiles/${this.project_id}`
        )
        .then((res) => {
          this.filesUploaded = res.data.project_uploaded_files;
          this.showFiles = true;
        })
        .catch((err) => console.log(err));
    },
    closeFiles() {
      this.showFiles = false;
    },
    getFilePath(file_path) {
      return `${process.env.VUE_APP_API_STORAGE}/storage/${file_path}`;
    },
    openModalResources() {
      this.showResources = !this.showResources;
    },
    getCdtiData(data) {
      this.cdtiData = data;
    },
    clickBackArrow() {
      this.$router.push({
        path: `/itinerary/${this.project_id}`,
      });
    },
    clickBack() {
      if (this.project_id != window.location.pathname.split("/")[2]) {
        this.initialCreated();
        this.$refs.header.loadCarrousel();
      }
    },
    initialCreated() {
      const data = window.location.pathname.split("/");
      this.project_id = parseInt(data[2]);
      this.station_id = parseInt(data[3]);
      this.solution_id = parseInt(data[4]);
      this.currentSolutions.push(parseInt(data[4]));
      axios
        .get(
          `${process.env.VUE_APP_API_URL}/general/checkIfUserIsInProject/${this.project_id}/${this.space.id}`
        )
        .then((response) => {
          if (response.data == false) {
            this.notInProject = true;
          } else {
            this.getRoleInProject();
            axios
              .get(
                `${process.env.VUE_APP_API_URL}/getMyProjectItinerary/${this.project_id}/${this.space.id}`
              )
              .then((res) => {
                this.themes = res.data.itinerary;
                this.assistants = res.data.assistants || [];
                this.observations =
                  this.roleInProject == 1
                    ? res.data.project.observations_member
                    : res.data.project.observations_tutor;
                if (this.observations) {
                  this.showObservationsModal = true;
                }
                this.lateralBarMap = prepareLateralBarMap(
                  res.data.itinerary,
                  this.roleInProject
                );
              })
              .catch((err) => console.log(err));
            this.showMap = true;
            this.checkIfAnswerIsReadyToCorrect();
            this.getSolutionName();
            this.getAnswers();
            this.getAllStationSolution();
            this.getProgressBarByProject();
            // this.repositionMap(this.solution_id);
          }
        });
      this.$nextTick().then(() => {
        let scrollContainer = this.$refs["scrollContainer"];
        scrollContainer.scrollTop = scrollContainer.scrollHeight;
      });
    },
    initialCdti() {
      const data = window.location.pathname.split("/");
      this.project_id = parseInt(data[2]);
      this.station_id = 33639;
      this.solution_id = 33624;
      this.loadedAnswerNames.push("");
      //this.loadedAnswerNames.push("Índice de éxito de tu empresa");
      this.getAnswers();
    },
    closeCdtiModal(show) {
      this.showCdtiResult = show;
    },
    setLoadingCdti(show) {
      this.loadingCdti = show;
    },
    deleteNotificationsInPart() {
      axios.patch(
        `${process.env.VUE_APP_API_URL}/solution/deleteNotificationsInPart`,
        {
          project_id: this.project_id,
          solution_id: this.solution_id,
          role: this.roleInProject,
        }
      );
    },
    deleteSelectedNotification(question_id) {
      axios.patch(
        `${process.env.VUE_APP_API_URL}/solution/deleteNotificationsInPart`,
        {
          project_id: this.project_id,
          solution_id: this.solution_id,
          question_id: question_id,
          role: this.roleInProject,
        }
      );
    },
    checkIfScrollTop() {
      if (this.scrollTopDown) {
        return true;
      }
      return false;
    },
    prepareAnswersFunc(data) {
      axios
        .get(
          `${process.env.VUE_APP_API_URL}/getMyProjectItinerary/${this.project_id}/${this.space.id}`
        )
        .then((res) => {
          this.lateralBarMap = prepareLateralBarMap(
            res.data.itinerary,
            this.roleInProject
          );
        })
        .catch((err) => console.log(err));

      this.prepareAnswers.push(data);
    },
    checkIfSolutionCompleted() {
      this.checkIfAnswerIsReadyToCorrect();
      axios
        .get(
          `${process.env.VUE_APP_API_URL}/solution/checkIfSolutionCompleted/` +
            this.project_id +
            "/" +
            this.solution_id
        )
        .then((response) => {
          let completed = false;
          if (response.data != 0) {
            completed = false;
          } else {
            completed = true;
          }
          this.$refs.map.solutionCompleted(this.solution_id, completed);
        });
    },
    getRoleInProject() {
      axios
        .get(
          `${process.env.VUE_APP_API_URL}/getRoleInProject/${this.project_id}`
        )
        .then((response) => {
          this.roleInProject = response.data;
        });
    },
    getCurrentIndex() {
      this.currentIndex = this.allSolutions.findIndex(
        (solution) => solution.id == this.solution_id
      );
      if (this.currentIndex == -1) {
        this.currentIndex = 0;
      }
    },
    closeCorrectionModal(sended) {
      this.sendCorrectionModal = false;
      if (sended) {
        this.solution_updated = false;
        window.location.reload();
      }
    },
    checkIfAnswerIsReadyToCorrect() {
      axios
        .get(
          `${process.env.VUE_APP_API_URL}/solution/checkIfAnswerIsReadyToCorrect`,
          {
            params: {
              project_id: this.project_id,
              role: this.roleInProject,
            },
          }
        )
        .then((response) => {
          response.data > 0
            ? (this.solution_updated = true)
            : (this.solution_updated = false);
        });
    },
    changePercentage(percentage) {
      this.percentage = percentage;
    },
    getProgressBarByProject() {
      axios
        .get(
          `${process.env.VUE_APP_API_URL}/getPercentageByProject/${this.project_id}`
        )
        .then((response) => {
          this.percentage = response.data;
        });
    },
    loadPreviousAnswers(index2) {
      //  get index of solution_id in allSolutions
      const index = this.allSolutions.findIndex(
        (solution) => solution.id == this.currentSolutions[0]
      );
      if (index != -1) {
        this.notBusy = false;
        this.loadingMoreSol = true;
        this.currentSolutions.unshift(this.allSolutions[index2 - 1].id);
        this.loadedPrev = this.allSolutions[index2 - 1].id;
        this.initialLoadState = true;
        axios
          .post(`${process.env.VUE_APP_API_URL}/solution/getAnswers`, {
            project_id: this.project_id,
            solution_id: this.currentSolutions[0],
            role: this.roleInProject,
          })
          .then((response) => {
            const sol = this.currentSolutions[0];
            this.initialLoadState = false;
            if (response.data != "") {
              if (response.data.answer == null) {
                response.data.answer = "";
                response.data.answer_id = 0;
                response.data.answer_order = 0;
                response.data.feedback_answer = "";
                response.data.interaction_tutor = 0;
                response.data.interaction_entrepreneur = 0;
                response.data.solution_id = this.currentSolutions[0];
              }
              for (let answer in response.data) {
                if (
                  typeof response.data[answer] === "object" &&
                  response.data[answer] !== null
                ) {
                  response.data[answer].solution_id = sol;
                }
              }
              this.showAnswers = false;

              //foreach preparedAnswers
              for (let i = 0; i < this.prepareAnswers.length; i++) {
                const data = this.prepareAnswers[i];
                if (this.roleInProject == 1) {
                  this.answers[data[2]][data[3]].feedback_answer =
                    data[1].content;
                } else {
                  this.answers[data[2]][data[3]].answer = data[1].content;
                }
                this.answers[data[2]][data[3]].answer_id = data[1].last_answer;
              }
              this.prepareAnswers = [];
              this.answers.unshift(response.data);
              //wait 0.01s second to show the answers
              setTimeout(() => {
                this.showAnswers = true;
              }, 10);
            }
            this.loadingMoreSol = false;
            this.loadedAnswerNames.unshift(this.allSolutions[index2 - 1].name);
          });
      }
      //set not busy to true after 4 seconds
      setTimeout(() => {
        this.notBusy = true;
      }, 4000);

      //wait 0.5s second to show the answers
      setTimeout(() => {
        this.hideFooters();
        this.loandingTop = false;
      }, 500);
    },
    hideFooters() {
      $(".footer-solution-just-1").hide();
    },
    loadMoreFunction() {
      if (window.innerHeight + window.scrollY >= document.body.offsetHeight) {
        if (this.notBusy) {
          if (
            this.currentSolutions[this.currentSolutions.length - 1] !=
            this.allSolutions[this.allSolutions.length - 1].id
          ) {
            this.scrollTopDown = false;
            this.initialLoadState = true;
            //Check if its the last element in allSolutions

            this.loadingMoreSol = true;
            this.loadedPrev = 0;
            this.notBusy = false;
            const index = this.allSolutions.findIndex(
              (solution) =>
                solution.id ==
                this.currentSolutions[this.currentSolutions.length - 1]
            );
            this.loadingMoreSol = true;
            this.currentSolutions.push(this.allSolutions[index + 1].id);
            this.getMoreAnswers();
            this.loadedAnswerNames.push(this.allSolutions[index + 1].name);
            setTimeout(
              function () {
                this.notBusy = true;
              }.bind(this),
              3000
            );
            this.checkStopDownScroll();
            this.loadingMoreSol = false;
            this.initialLoadState = false;
            this.hideFooters();
          }
        }
      } else if (window.scrollY === 0) {
        try {
          if (this.notBusy) {
            // get index of solution_id in allSolutions
            const index = this.allSolutions.findIndex(
              (solution) => solution.id == this.currentSolutions[0]
            );
            if (index > 0) {
              if (index != 0) {
                this.loandingTop = true;
              } else {
                this.loandingTop = false;
              }
              this.scrollTopDown = true;
              setTimeout(
                function () {
                  this.loadPreviousAnswers(index);
                }.bind(this),
                500
              );
            }
          }
        } catch (e) {
          this.loandingTop = false;
        }
      }
    },
    checkStopDownScroll() {
      if (
        this.allSolutions.length - 1 ===
        this.allSolutions.indexOf(this.solution_id)
      ) {
        this.canScrollDown = false;
      }
    },
    uploadCurrentDataOnScroll(more) {
      const solutions = this.getSolutionsbyStation(this.station_id);
      const posc = solutions.map((e) => e.id).indexOf(this.solution_id);
      if (solutions.length - 1 == posc) {
        const newStationIndex = this.allStations
          .map((e) => e.id)
          .indexOf(this.station_id);
        if (this.allStations.length - 1 != newStationIndex) {
          let newStation = 0;
          more[0]
            ? (newStation = this.allStations[newStationIndex + 1].id)
            : (newStation = this.allStations[newStationIndex - 1].id);
          const newSolution = this.getSolutionsbyStation(newStation)[0];
          this.station_id = newStation;
          this.solution_id = newSolution.id;
        }
      } else {
        if (more[1] == 9999) {
          if (more[0]) {
            this.solution_id = solutions[posc + 1].id;
          } else {
            if (solutions[posc - 1] != undefined) {
              this.solution_id = solutions[posc - 1].id;
            }
          }
        } else {
          this.solution_id = solutions[more[1]].id;
        }
      }
    },
    getMoreAnswers() {
      this.loadingMore = true;
      axios
        .post(`${process.env.VUE_APP_API_URL}/solution/getAnswers`, {
          project_id: this.project_id,
          solution_id: this.currentSolutions[this.currentSolutions.length - 1],
          role: this.roleInProject,
        })
        .then((response) => {
          if (response.data != "") {
            if (response.data.answer == null) {
              response.data.answer = "";
              response.data.answer_id = 0;
              response.data.answer_order = 0;
              response.data.feedback_answer = "";
              response.data.interaction_tutor = 0;
              response.data.interaction_entrepreneur = 0;
            }
            const sol = this.currentSolutions[this.currentSolutions.length - 1];

            for (let answer in response.data) {
              if (
                typeof response.data[answer] === "object" &&
                response.data[answer] !== null
              ) {
                response.data[answer].solution_id = sol;
              }
            }
            this.answers.push(response.data);
          } else {
            this.answers.push("");
          }
        });
    },
    getAllStationSolution() {
      axios
        .post(`${process.env.VUE_APP_API_URL}/solution/getStationsByTypology`, {
          project_id: this.project_id,
        })
        .then((response2) => {
          this.allStations = response2.data;
        });

      axios
        .post(
          `${process.env.VUE_APP_API_URL}/solution/getSolutionsByTypology`,
          {
            project_id: this.project_id,
          }
        )
        .then((response3) => {
          this.allSolutions = response3.data;
        });
    },
    getSolutionsbyStation(station_id) {
      return this.allSolutions.filter(
        (solution) => solution.station_id == station_id
      );
    },
    getStationbySolution(solution_id) {
      const solution = this.allSolutions.filter(
        (solution) => solution.id == solution_id
      );
      return solution[0].station_id;
    },
    goToSolution(data) {
      //   this.deleteNotificationMap();
      this.answers = [];
      this.loadedAnswerNames = [];
      this.currentSolutions = [];
      this.station_id = data[0];
      this.solution_id = data[1];
      this.currentSolutions.push(data[1]);
      this.loadedAnswerNames.push(data[2]);
      history.replaceState(
        null,
        null,
        `/parts/${this.project_id}/${data[0]}/${data[1]}`
      );
      this.getAnswers();
      //   this.deleteNotificationsInPart();
      //wait 0.1s to load the new data
      setTimeout(
        function () {
          window.scrollTo({
            top: 0,
            left: 0,
            behavior: "instant",
          });
        }.bind(this),
        100
      );
    },
    changeProject(data) {
      history.pushState(null, null, window.location.pathname);
      this.project_id = data[0];
      this.station_id = data[1];
      this.solution_id = data[2];
      this.answers = [];
      this.loadedAnswerNames = [];
      this.currentSolutions = [];
      this.notBusy = false;
      this.showMap = false;
      history.replaceState(
        null,
        null,
        `/parts/${data[0]}/${data[1]}/${data[2]}`
      );
      this.initialCreated();
    },
    getAnswers() {
      this.loading = true;
      this.notBusy = false;
      let url = "solution/getAnswers";
      if (this.cdti) url = `cdti/getInvertibilityIndex`;
      axios
        .post(`${process.env.VUE_APP_API_URL}/${url}`, {
          project_id: this.project_id,
          solution_id: this.solution_id,
          role: this.roleInProject,
        })
        .then((response) => {
          setTimeout(
            function () {
              //response.data.solution_id = this.solution_id;
              this.answers = [];
              this.answers[0] = response.data;
              for (let answer in this.answers[0]) {
                this.answers[0][answer].solution_id = this.solution_id;
              }
              this.loading = false;
              this.notBusy = true;
            }.bind(this),
            1000
          );
        });
    },
    getSolutionName() {
      axios
        .post(`${process.env.VUE_APP_API_URL}/solution/getSolutionName`, {
          solution_id: this.solution_id,
        })
        .then((response) => {
          this.loadedAnswerNames.push(response.data);
        })
        .catch(() => {
          this.loadedAnswerNames.push("Solution");
        });
    },
    closeErrorsWindow() {
      clearTimeout(this.setTimeouts);
      this.errors = [];
    },
    openErrorsAlert() {
      if (this.errors.length == 0) {
        this.errors.push("error");

        this.errorTime = {
          animationDuration: "12s",
          animationName: "timebar_progress_x",
        };
        this.setTimeouts = setTimeout(() => {
          this.errors = [];
          this.$refs.alerts.succesful = false;
        }, 12000);
      }
    },
    openModalAlert(oldState, state) {
      this.errorEmail = true;
      this.$refs.alerts.title = "warning";
      this.$refs.alerts.text = "The status has been updated from";
      this.$refs.alerts.text2 = "to";
      this.$refs.alerts.variable1 = this.langFilter(oldState);
      this.$refs.alerts.variable2 = this.langFilter(state);
      this.$refs.alerts.purple = true;
      this.openErrorsAlert();
    },
    errorFileUpload() {
      //alert("Error uploading file");
      this.errorEmail = true;
      this.$refs.alerts.title = "Error uploading the file";
      this.$refs.alerts.text = "The file can't be more than 5MB";
      this.openErrorsAlert();
    },
    editedSolution(data) {
      this.edited = data;
    },
    deleteNotificationMap(question_id) {
      //get all elements with name="solution-map-notification"
      const element = document.getElementById(
        "solution-notification-circle-" + this.solution_id + "-" + question_id
      );
      //if there are elements with that name
      //hide the element
      if (element) element.style.display = "none";

      const elements = document.getElementsByName(
        "solution-notification-circle-" + this.solution_id
      );
      let found = false;
      for (let i = 0; i < elements.length; i++) {
        if (elements[i].style.display != "none") {
          found = true;
          return;
        }
      }
      if (!found) {
        const el = document.getElementById(
          "solution-border" + this.solution_id
        );
        if (el) {
          el.classList.remove("solution-map-notification");
        }
      }
    },
    repositionMap(data, nulable) {
      const solution_id = data[0];
      const question_id = data[1];
      if (nulable) {
        this.deleteSelectedNotification(question_id);
        this.deleteNotificationMap(question_id);
      }
      if (solution_id != this.solution_id) {
        const station_id = this.getStationbySolution(solution_id);
        this.station_id = station_id;
        this.solution_id = solution_id;
        history.pushState(null, null, window.location.pathname);
        history.replaceState(
          null,
          null,
          `/parts/${this.project_id}/${this.station_id}/${this.solution_id}`
        );
      }
    },
    sendCorrection() {
      this.sendCorrectionModal = !this.sendCorrectionModal;
    },
  },
  computed: {
    isMainTab() {
      return this.selectedTab === 0;
    },
    isExpertType() {
      return this.selectedTab === 1;
    },
    tabHasAssistant() {
      const matchAssistant = this.assistants.find(
        ({ assistant_type }) => assistant_type === this.selectedTab
      );

      return !!matchAssistant;
    },
    getAssistentType() {
      return this.isExpertType ? "expertAssistant" : "evaluatorAssistant";
    },
    dynamicTranslations() {
      return {
        stillDoNotHave: this.langFilter("stillDoNotHave"),
        assistantType: this.langFilter(this.getAssistentType).toLowerCase(),
        configured: this.langFilter("configured").toLowerCase(),
        configureInstruction: this.langFilter("configureInstruction"),
        clickHere: this.langFilter("clickHere").toLowerCase(),
      };
    },
    tooltipTextTranlated() {
      const selectedText = this.isExpertType
        ? "expertAssistantTooltip"
        : "evaluatorAssistantTooltip";

      return this.langFilter(selectedText);
    },
  },
  mixins: [translationMixin],
};
</script>
